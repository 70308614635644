var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Container } from '@mui/material';
import { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useParams } from 'react-router';
import CustomBreadcrumbs from '../../components/custom-breadcrumbs';
import { useSettingsContext } from '../../components/settings';
import { dispatch, useSelector } from '../../redux/store';
import { PATH_DASHBOARD } from '../../routes/paths';
import { getOneBooksCashDeskPayment } from '../../redux/slices/bookCashDeskPayments/actions';
import { BooksCashDeskPaymentsForm } from '../../sections/@dashboard/booksCashDeskPayments/form';
// ----------------------------------------------------------------------
export default function BooksCashDeskPaymentsDetailsPage() {
    var themeStretch = useSettingsContext().themeStretch;
    var id = useParams().id;
    useEffect(function () {
        if (id) {
            dispatch(getOneBooksCashDeskPayment({ booksCashDeskPaymentId: id }));
        }
    }, [id]);
    var booksCashDeskPayment = useSelector(function (state) { return state.booksCashDeskPayments; }).booksCashDeskPayment;
    return (_jsxs(_Fragment, { children: [_jsx(Helmet, { children: _jsx("title", { children: " Books Cash Desk Payments: Details " }) }), _jsxs(Container, __assign({ maxWidth: themeStretch ? false : 'lg' }, { children: [_jsx(CustomBreadcrumbs, { heading: "View a Books Cash Desk Payment", links: [
                            {
                                name: 'Books Cash Desk Payment',
                                href: PATH_DASHBOARD.books.cashDeskPayments.root,
                            },
                            { name: 'View' },
                        ] }), _jsx(BooksCashDeskPaymentsForm, { paymentDetails: true, currentBooksCashDeskPayment: booksCashDeskPayment }, new Date().toISOString())] }))] }));
}
